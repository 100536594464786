#stoerer-aktion {
    position: absolute;
    max-width: 300px;
    width: 100%;
    left: 6%;
    top: 30%;
    @media (max-width: 1199px) {
        max-width: 220px; }
    @media (max-width: 767px) {
        max-width: 180px; }
    @media (max-width: 620px) {
        max-width: 30%;
        top: 25%; } }
#aktion-page {
    .text-centered-mobil {
        @media (max-width: 1024px) {
            text-align: center !important; } }
    .button-centered-mobile {
        @media (max-width: 1024px) {
            margin: auto !important; } }
    #TeamSlider {
        max-width: 60%;
        * {
            text-align: center; } }
    h1,h2,h3,h4,h5 {
        font-size: 3em !important;
        line-height: 3.5rem !important;
        margin-bottom: 30px !important; }
    .headline_lila {
        color: #29408e !important; } }
.col-70-width-desktop {
    padding-right: 300px; }
.row-direction {
    flex-direction: row !important;
    align-items: center !important; }
.pb-pt-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
.row_centered-70_porcent {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-between;
    & > div {
        width: 30%;
        text-align: center;
        @media (max-width: 767px) {
            width: 100%; } } }
.row_centered-70_porcent.icons {
    p {
        max-width: 100% !important; }
    img {
        max-width: 150px; } }

.row_centered-90_porcent {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-between;
    @media (max-width: 999px) {
        justify-content: center; }
    & > div {
        width: 18%;
        text-align: center;
        @media (max-width: 999px) {
            width: 30%;
            margin-bottom: 20px; }
        @media (max-width: 767px) {
            width: 100%; } } }
.row_centered-90_porcent.icons {
    p {
        max-width: 100% !important;
        margin-top: 10px; }
    img {
        max-width: 150px;
        @media (max-width: 1199px) {
            max-width: 120px; } } }

.mobile_pb {
    @media (max-width: 991px) {
        padding-bottom: 60px !important; } }
.button_centered {
    margin: auto !important;
    max-width: 200px !important;
    a {
        width: 100% !important; } }
.mb-5 {
    margin-bottom: 25px !important; }
.mt-5 {
    margin-top: 25px !important; }
.zitat-content {
    padding-left: 60px;
    padding-right: 60px;
    @media (max-width: 767px) {
        padding-left: 0px !important;
        padding-right: 0px !important; }
    .zitat {
        position: relative;
        &:before {
            position: absolute;
            left: -40px;
            top: -5px;
            content: "";
            background: url("/assets/img/sonnenbrille-aktion/zitat-before@2x.png");
            background-size: contain;
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            @media (max-width: 767px) {
                left: 0px !important; } }
        &:after {
            position: absolute;
            right: -10px;
            bottom: -10px;
            content: "";
            background: url("/assets/img/sonnenbrille-aktion/zitat-after@2x.png");
            background-size: contain;
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            @media (max-width: 767px) {
                right: 0px !important; } } } }
.square-bg-image {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    min-height: 700px !important;
    @media (max-width: 767px) {
        min-height: 400px !important; } }

.order-1 {
    order: 1; }
.order-2 {
    order: 2; }
.order-lg-1 {
    @media (min-width: 1024px) {
        order: 1; } }
.order-lg-2 {
    @media (min-width: 1024px) {
        order: 2; } }
