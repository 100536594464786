form {
    max-width: 600px;
    background: #efefef;
    padding: 20px;
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="range"],
    textarea {
        width: 100%;
        margin-bottom: 20px;
        padding: 5px 10px;
        margin-top: 5px;
        outline: none;
        appearance: {}
        border: none; }
    input {
        padding: 5px 10px;
        outline: none;
        appearance: {}
        border: none;
        margin-bottom: 20px;
        margin-top: 5px; }
    label {
        display: inline-block; }
    .labelRadio {
        margin-right: 20px; }
    input[type="checkbox"],
    input[type="radio"] {
        margin-right: 5px; } }
