.stickies {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99999;
    transition: all 500ms ease;
    .sticky {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: $blue;
        color: white;
        padding: 10px;
        font-size: 1.1em;
        margin: 10px 0;
        width: 80px;
        img {
            margin-bottom: 5px; } }
    &.scrolled {
        right: -80px; } }
